import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, NavLink } from "react-router-dom";
import './App.css';


const g_enableNavbar = true;

function GetBackEndTestAddress()
{
  if (window.location.protocol === null)
    return "http:////127.0.0.1:8060";

  if (process.env.NODE_ENV !== 'production') 
    return window.location.protocol + "//" + window.location.hostname + ":8060";
  return window.location.protocol + "//" + window.location.host;
}

function Footer() {
    return  <footer className="footer">
    Общие вопросы - <a href="mailto:wtf@ogryzkov.ru">wtf@ogryzkov.ru</a> 
    <br/>
    © {new Date().getFullYear()} Sergey Ogryzkov 
    </footer>
  }


  function NavBar() {
      if (g_enableNavbar)
            return <div className="bg-img">
                        <div className="navcontainer">
                         <ul>
                             <NavLink exact to="/" className="NavLinkStyle" activeClassName="NavLinkStyleActive">Photo</NavLink>
                             <NavLink to="/video" className="NavLinkStyle" activeClassName="NavLinkStyleActive">Video</NavLink>
                          </ul>
                        </div>
                    </div>
      else
        return <div/>
  }

class ColumnImage extends Component
{
    constructor(props) {
        super(props)
        this.state = {gallery: props.gallery};        
    }

    render() {
        const Columns = this.state.gallery.map(
            (data) => 
            <div className="column" key={data.key} id={data.ids}>            
                <a href={data.short_url} target="_self"  >
                    <div className="image_frame"  >
                        <img className="image" src={data.thumb} alt={"Image " + data.title}  />
                        <div className="overlay" >{data.title}</div>
                    </div>
                </a>                                
            </div>
            );        

        return <div class = "row" >{Columns} </div>
    }
}



function GetDefaultPageNotFoundText(){
    return <div>
        Ups. The page was not found<br/>
        But, you can try to go <strong><a href="/" >back</a></strong> and have fun there. <br/>
        Or, you can write <a href="mailto:sergey@ogryzkov.ru">to me</a> about that problem, or any other problem.
    </div>
}

class CustomErrorPage extends Component {
    constructor(props){
        super(props)
        
        this.state = {
            errorText: props.errorText,
          };
    }

    render() {
        const s = this.state;  
    
        return <div className = "error_image" >
        <div className= "error_text">
            {s.errorText}
        </div>
    </div>;
    }
}

function DefaultErrorPage() {
    return <CustomErrorPage errorText={GetDefaultPageNotFoundText()}/>;
  }

class ColumnVideo extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            gallery: props.gallery, 
        };       
    }

    render() {

        const Columns = this.state.gallery.map(
            (data) => 
            <div className="column" key={data.key} id={data.id} >          
                <div className="video_ref" >  
                    <a href={data.short_url} target="_self"  >
                        <div className="image_frame"  >
                            <img className="image" src={data.thumb} alt={"Image " + data.title}/>
                            <div className="overlay" >{data.title}</div>
                        </div>
                    </a>
                </div>
            </div>
            );        

        return <div class = "row" >{Columns} </div>
    }
}

class ColumnVideoSelected extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            data: props.data,
        };       
    }

    render() {
        
        const VideoData = this.state.data;
        
        return <div class = "row" >
            <div className="column_video" key={VideoData.key}>
                <iframe src={VideoData.link} className="video_frame_one"></iframe>                
            </div>
        </div>
    }
}


class BackEndRequestedComponent extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: [],            
            isLoading: true,
            err: null,
            ConvertArrivedDataFunc : props.ConvertArrivedDataFunc,
            Url: props.Url,
            title: props.title,
            DisplayFunction: props.DisplayFunction,
          };

        this.OnDataArrived = this.OnDataArrived.bind(this);
        this.OnErrorLoadingData = this.OnErrorLoadingData.bind(this);
        this.TransferFailed = this.TransferFailed.bind(this);
    }

    OnDataArrived(data) {
        try
        {
            const dataJson = JSON.parse(data);
            this.setState(
                { 
                    data: this.state.ConvertArrivedDataFunc(dataJson),                     
                    isLoading: false 
                }
            );
        }
        catch(e)
        {
            this.setState(
                { 
                    err: "error parsing response from " + GetBackEndTestAddress() + " : " + data
                }
            );
        }        
    }

    OnErrorLoadingData(status, text) {
        this.setState(
            { 
                err: "response with status " + status + " : " + text
            }
        );
    }

    TransferFailed(evt) {
        this.OnErrorLoadingData(0, evt.error.toString());
    }     
    
    MakeGalleryRequest(url, callbackOk, callbackError) {
        let Http = new XMLHttpRequest();
        Http.open("GET", url);
        Http.send();
        Http.onreadystatechange=(e)=>{
            if (Http.status === 200) {
                if (Http.readyState === 4) {
                    callbackOk(Http.responseText);
                }                
            }
            else if (Http.status !== 0){
                callbackError(Http.status, Http.responseText);
            }
            else {
                callbackError(Http.status, "Unknown error during communication with " + url);               
            }
        }
        //Http.addEventListener("error", this.TransferFailed);
        return Http;
    }

    componentDidMount() {      
        this.Http = this.MakeGalleryRequest(this.state.Url, this.OnDataArrived, this.OnErrorLoadingData);
        document.title = this.state.title;
      }

      componentWillUnmount() {
        if (this.Http) {
            this.Http.abort();
        }  
      }      

    render() {
        const s = this.state;        

        if (s.err != null) {
            return <CustomErrorPage errorText={"Error communicating with back end: " + s.err.toString()}/>;
        }

        if (s.isLoading) {
            return <div className = "error" >Loading...</div>;
        }

        return this.state.DisplayFunction(s.data);
    }
}


function HomeVideo() {
    function DataFilterFunc(data)
    {
        return data.map(
            (c) => {
                c["thumb"] = GetBackEndTestAddress() + c["thumb"];
                return c; 
            }
        );
    }

    function MainDisplayFunction(data) {
        return ( 
            <div className = "gallery" >                     
            <ColumnVideo gallery={data}></ColumnVideo> 
            </div >
        );
    }

    return <BackEndRequestedComponent 
        Url={GetBackEndTestAddress() + "/api/video/"}   
        ConvertArrivedDataFunc={DataFilterFunc} 
        title={"Magic video gallery"}
        DisplayFunction={MainDisplayFunction}
    />
}


function HomeGallery() {
    function DataFilterFunc(data)
    {
        return data.map(
            (c) => {
                c["short_url"] = GetBackEndTestAddress() + c["short_url"]; 
                c["thumb"] = GetBackEndTestAddress() + c["thumb"];
                return c; 
            }
        );
    }

    function MainDisplayFunction(data) {
        return ( 
            <div className = "gallery" >                     
            <ColumnImage gallery={data}></ColumnImage> 
            </div >
        );
    }

    return <BackEndRequestedComponent 
        Url={GetBackEndTestAddress() + "/gallery/"}   
        ConvertArrivedDataFunc={DataFilterFunc} 
        title={"Magic image gallery"}
        DisplayFunction={MainDisplayFunction}
    />
}


function SelectedVideo({match}) {
    const id = match.params.id;

    function DataFilterFunc(data)
    {
        return data;
    }

    function MainDisplayFunction(data) {
        return ( 
            <div className = "gallery" >                     
            <ColumnVideoSelected data={data}></ColumnVideoSelected> 
            </div >
        );
    }

    return <BackEndRequestedComponent 
        Url={GetBackEndTestAddress() + "/api/video/" + id}   
        ConvertArrivedDataFunc={DataFilterFunc} 
        title={"Magic video"}
        DisplayFunction={MainDisplayFunction}
    />;
}


function App() {
    return (
      <BrowserRouter>
        <NavBar />  
        <Switch >
            <Route exact path="/" component={HomeGallery} />   
            <Route exact path="/video/" component={HomeVideo} />
            <Route path="/video/:id" component={SelectedVideo} />     
            <Route component={DefaultErrorPage} />   
        </Switch>       
        <Footer />  
      </BrowserRouter>
    );
  }

export default App;
export {CustomErrorPage};
export {HomeGallery};