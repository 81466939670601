import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
<div className="main_frame">
<App />
</div>
, 
document.getElementById('root'));
